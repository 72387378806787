import { functions } from "../firebase/firebase-utils";
import { httpsCallable } from "firebase/functions";

export default async function uploadImageFromUrl(url) {
    try {
        const uploadImage = httpsCallable(functions, "uploadImageFromUrl");
        const downloadUrl = await uploadImage({ url: url });
        console.log(downloadUrl);
    } catch (err) {
        console.log(err);
    }
}
