import React, { useEffect, useState } from "react";
import { GridContainer, GridDivider, GridFlexBox, GridItem, Loading, Title } from "../../themes/themes";
import { onSnapshot, collection, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "../../firebase/firebase-utils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import axios from "axios";
const INDEX_ACCESS_KEY = process.env.REACT_APP_INDEX_ACCESS_KEY;

const DisplayUploadedDocuments = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            const unsubscribe = onSnapshot(
                collection(db, "documents"),
                (querySnapshot) => {
                    const fetchedDocuments = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setDocuments(fetchedDocuments);
                },
                (error) => {
                    console.error("Error fetching documents: ", error);
                }
            );

            return () => unsubscribe();
        };

        fetchData();
    }, []);

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleDelete = async (id, fileName) => {
        if (!window.confirm("Are you sure you want to delete this file?")) {
            return;
        }
        try {
            const fileRef = ref(storage, `gptDocuments/${fileName}`);
            await deleteObject(fileRef);
            await deleteDoc(doc(db, "documents", id));
            console.log("File and database entry deleted successfully");
        } catch (error) {
            console.error("Error deleting file and database entry: ", error);
        }
    };

    const handleStartIndexing = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `https://asia-southeast1-whatsapp-api-eea64.cloudfunctions.net/index_documents?input_text=${INDEX_ACCESS_KEY}`
            );
            setLoading(false);
            console.log("Indexing started:", response);
        } catch (error) {
            console.error("Error starting indexing:", error);
            prompt("Error starting indexing:", error);
            setLoading(false);
        }
    };

    return (
        <GridContainer>
            <GridFlexBox>
                <Title>Uploaded Documents</Title>
            </GridFlexBox>
            <GridDivider />
            {documents.map(({ id, name, description, url, size, indexed }) => (
                <GridFlexBox key={id} container alignItems="center">
                    <GridItem xs={3}>{name}</GridItem>
                    <GridItem xs={3}>{description}</GridItem>
                    <GridItem xs={2}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            Download
                        </a>
                    </GridItem>
                    <GridItem xs={2}>{formatBytes(size)}</GridItem>
                    <GridItem xs={1}>{indexed ? "Indexed" : "Not Indexed"}</GridItem>
                    <GridItem xs={1}>
                        <IconButton onClick={() => handleDelete(id, name)}>
                            <DeleteIcon />
                        </IconButton>
                    </GridItem>
                </GridFlexBox>
            ))}
            <Loading loading={loading} />
            <GridFlexBox>
                <GridItem xs={12}>
                    <Button variant="contained" color="primary" onClick={handleStartIndexing} disabled={loading}>
                        Start Indexing
                    </Button>
                </GridItem>
            </GridFlexBox>
        </GridContainer>
    );
};

export default DisplayUploadedDocuments;
