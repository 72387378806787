import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SignInPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import Menu from "./components/Menu";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage";
import SignOutPage from "./pages/SignOutPage";
import WhatsappMessagePage from "./pages/WhatsappMessagePage";
import TestRawMessagesPage from "./tests/TestRawMessagesPage";
import UploadImageFromUrlPage from "./tests/UploadImageFromUrlPage";
import FacebookLoginPage from "./pages/FacebookLoginPage";
import UploadDataPage from "./pages/UploadDataPage";
import Test from "./tests/Test";
import GptIndexPage from "./pages/GptIndexPage/GptIndexPage";
import TelegramMessagesPage from "./pages/TelegramMessagesPage/TelegramMessagesPage";

function App() {
    const { user } = useContext(AuthContext);

    if (user) {
        return withUser(user);
    } else {
        return noUser(user);
    }
}

export default App;

function withUser(user) {
    return (
        <div>
            <Menu user={user} />

            <Routes>
                <Route index element={<HomePage />} />

                <Route exact path="/whatsapp-message-page" element={<WhatsappMessagePage />} />
                <Route exact path="/upload-data-page" element={<UploadDataPage />} />
                <Route exact path="/test-raw-messages-page" element={<TestRawMessagesPage />} />
                <Route exact path="/upload-image-from-url-page" element={<UploadImageFromUrlPage />} />
                <Route exact path="/facebook-login-page" element={<FacebookLoginPage />} />
                <Route exact path="/gpt-index-page" element={<GptIndexPage />} />
                <Route exact path="/telegram-messages-page" element={<TelegramMessagesPage />} />
                <Route exact path="/sign-out" element={<SignOutPage />} />
                <Route exact path="/test" element={<Test />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </div>
    );
}

function noUser() {
    return (
        <div>
            <Menu user={null} />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route exact path="/sign-up" element={<SignUpPage />} />
                <Route exact path="/sign-in" element={<SignInPage />} />
                <Route path="*" element={<LandingPage />} />
                <Route path="forgot-password-page" element={<ForgotPasswordPage />} />
            </Routes>
        </div>
    );
}
