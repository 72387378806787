import { Container } from "@mui/system";
import { ButtonC, GridContainer, GridFlexBox, TextFieldC, Title } from "../themes/themes";
import { useState } from "react";
import uploadImageFromUrl from "../functions/uploadImageFromUrl";
import callTestFunction from "./callTestFunction";

export default function UploadImageFromUrlPage() {
    const [url, setUrl] = useState(
        "https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej.png"
    );

    function handleClick() {
        uploadImageFromUrl(url);
    }

    function handleTestEnv() {
        console.log(process.env.REACT_APP_FBAPPID);
    }

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Upload Image</Title>
                </GridFlexBox>
                <GridFlexBox>
                    <TextFieldC value={url} onChange={(e) => setUrl(e.current.value)} small />
                </GridFlexBox>
                <GridFlexBox>
                    <ButtonC onClick={handleClick}>Upload</ButtonC>
                </GridFlexBox>
                <GridFlexBox>
                    <ButtonC onClick={() => callTestFunction()}>Call Test Function</ButtonC>
                </GridFlexBox>
                <GridFlexBox>
                    <ButtonC onClick={() => handleTestEnv()}>Environment Variable</ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
