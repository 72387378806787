import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Container } from "@mui/material";
import { useContext, useState } from "react";
import UploadExcel from "../components/UploadExcel";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { useInput } from "../context-utils/InputContext";
import useList from "../context-utils/ListContext";
import useNotification from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../themes/themes";

const REQUIRED_FIELDS = [
    { label: "Name", id: "name" },
    { label: "Phone", id: "phone" },
    { label: "Email", id: "email" },
    { label: "Remark", id: "remark" },
    { label: "Source", id: "source" },
    { label: "Campaign", id: "campaign" }
];

export default function UploadDataPage() {
    const [requiredFields, setRequiredFields] = useState(REQUIRED_FIELDS);

    const { user } = useContext(AuthContext);

    const confirmation = useConfirmation();
    const list = useList();
    const getInput = useInput();
    const notify = useNotification();

    const handleUpload = async (data) => {
        const leads = data;
        console.log(leads);
        const message = (
            <div>
                <div>Name: {leads[0].name}</div>
                <div>Phone: {leads[0].phone}</div>
                <div>Email: {leads[0].email}</div>
                <div>Remark: {leads[0].remark}</div>
                <div>Source: {leads[0].source}</div>
                <div>Campaign: {leads[0].campaign}</div>
                <br />
            </div>
        );
        const response = await confirmation("Confirm upload", message);
    };

    return (
        <Container maxWidth="lg">
            <GridContainer>
                <GridFlexBox>
                    <Title>Upload Data</Title>
                    <CloudUploadOutlinedIcon style={{ marginLeft: "16px" }} />
                </GridFlexBox>
                <GridDivider />
                <UploadExcel
                    handleUpload={handleUpload}
                    requiredFields={requiredFields}
                    setRequiredFields={setRequiredFields}
                />
            </GridContainer>
        </Container>
    );
}
