
export default function mapSnapshot(snapshot) {
    if (!snapshot.empty) {
        const data = snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id
        }));
        return data;
    } else {
        return null;
    }
}
