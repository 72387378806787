import { Container } from "@mui/material";
import UploadFiles from "../../components/UploadFiles";
import DisplayUploadedDocuments from "./DisplayUploadedDocuments";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";

export default function GptIndexPage() {
    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>GPT Index Page</Title>
                </GridFlexBox>
                <GridDivider />
                <UploadFiles />
                <GridDivider />
                <DisplayUploadedDocuments />
            </GridContainer>
        </Container>
    );
}
