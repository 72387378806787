import { blue } from "@mui/material/colors";

export const customThemes = {
    palette: {
        blue: { main: blue[500], contrastText: "#FFFFFF", },
    },

    typography: {
        fontFamily: `'Montserrat', sans-serif`
    },
    button: {
        fontFamily: `'Montserrat', sans-serif`
    }
};
