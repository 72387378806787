/* eslint-disable no-restricted-globals */
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";

//Main Database
const firebaseConfig = {
    apiKey: "AIzaSyDF6DsLBkWzhsnO1Z2URbRtwgpnIwl_1jM",
    authDomain: "whatsapp-api-eea64.firebaseapp.com",
    projectId: "whatsapp-api-eea64",
    storageBucket: "whatsapp-api-eea64.appspot.com",
    messagingSenderId: "1084592507284",
    appId: "1:1084592507284:web:72c5db7d0a14f1779aa781",
    measurementId: "G-8E4HPMEP41"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const dbRT = getDatabase();

// if (location.hostname === "localhost") {
//     connectFirestoreEmulator(db, "localhost", 8080);
//     connectFunctionsEmulator(functions, "localhost", 5001);
//     connectAuthEmulator(auth, "http://localhost:9099");
//     connectDatabaseEmulator(dbRT, "localhost", 9000);
// }

export default app;
