import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function saveToken(longToken, user) {
    try {
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, { longAccessToken: longToken });
        console.log("Successfully added long token to: ", user.displayName);
    } catch (error) {
        console.log(error);
    }
}
