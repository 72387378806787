import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../firebase/firebase-utils";

export default async function uploadToStorage(files) {
    console.log(files);
    try {
        for (const { file, description } of files) {
            const fileSize = file.size;
            const fileRef = ref(storage, `gptDocuments/${file.name}`);
            const blobName = fileRef.fullPath;
            await uploadBytes(fileRef, file);
            const downloadURL = await getDownloadURL(fileRef);
            console.log("Files uploaded successfully");
            await addDoc(collection(db, "documents"), {
                name: file.name,
                url: downloadURL,
                description,
                indexed: false,
                size: fileSize,
                blobName
            });
            console.log("Document added to database");
        }
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}
