import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function sendInteractiveMessage(longToken, fromNumber, toNumber, type, user, openNotification) {
    const buttonText = "Button";
    const buttons = [
        {
            type: "reply",
            reply: {
                id: "UNIQUE_BUTTON_ID_1",
                title: "BUTTON_TITLE_1"
            }
        },
        {
            type: "reply",
            reply: {
                id: "UNIQUE_BUTTON_ID_2",
                title: "BUTTON_TITLE_2"
            }
        }
    ];

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${longToken}`);
    myHeaders.append("Content-Type", "application/json");

    var rawButtons = JSON.stringify({
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: toNumber,
        type: "interactive",
        interactive: {
            type: "button",
            body: {
                text: buttonText
            },
            action: {
                buttons: buttons
            }
        }
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: rawButtons,
        redirect: "follow"
    };

    let message;
    buttons.forEach((button, i) => {
        console.log(button);
        if (i === 0) {
            message = buttonText + ` : [${button.reply.title}]`;
        }
        if (i > 0) {
            message = message + ` [${button.reply.title}]`;
        }
    });
    console.log(message);

    try {
        const response = await fetch(`https://graph.facebook.com/v16.0/${fromNumber}/messages`, requestOptions);
        const result = await response.text();
        const data = JSON.parse(result);

        const wamId = data?.messages?.[0]?.id;
        const roomId = fromNumber + "-" + toNumber;
        const roomRef = doc(db, "messages", roomId);
        await setDoc(roomRef, { lastUpdated: new Date(), user: user.id, toNumber: toNumber }, { merge: true });
        console.log("Room ", roomId, " updated");

        const messageRef = doc(db, "messages", roomId, "messages", wamId);
        await setDoc(messageRef, { fromNumber, toNumber, message, date: new Date(), user: user.id, wamId, type });
        console.log("Added message into Firestore");
    } catch (err) {
        console.log(err);
    }
}

// const headerText = "Testing123";
// const bodyText = "Welcome to wonderland";
// const footerText = "this is my feet";
// const buttonText = "Button";\
//
// const sessions = [
//     {
//         title: "SECTION_1_TITLE",
//         rows: [
//             {
//                 id: "SECTION_1_ROW_1_ID",
//                 title: "SECTION_1_ROW_1_TITLE",
//                 description: "SECTION_1_ROW_1_DESCRIPTION"
//             },
//             {
//                 id: "SECTION_1_ROW_2_ID",
//                 title: "SECTION_1_ROW_2_TITLE",
//                 description: "SECTION_1_ROW_2_DESCRIPTION"
//             }
//         ]
//     },
//     {
//         title: "SECTION_2_TITLE",
//         rows: [
//             {
//                 id: "SECTION_2_ROW_1_ID",
//                 title: "SECTION_2_ROW_1_TITLE",
//                 description: "SECTION_2_ROW_1_DESCRIPTION"
//             },
//             {
//                 id: "SECTION_2_ROW_2_ID",
//                 title: "SECTION_2_ROW_2_TITLE",
//                 description: "SECTION_2_ROW_2_DESCRIPTION"
//             }
//         ]
//     }
// ];

// var rawList = JSON.stringify({
//     messaging_product: "whatsapp",
//     recipient_type: "individual",
//     to: toNumber,
//     type: "interactive",
//     interactive: {
//         type: "list",
//         header: {
//             type: "text",
//             text: headerText
//         },
//         body: {
//             text: bodyText
//         },
//         footer: {
//             text: footerText
//         },
//         action: {
//             button: buttonText,
//             sections: sessions
//         }
//     }
// });
