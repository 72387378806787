import { Button, Container } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../themes/themes";
import { sample } from "./rawMessagesSample";

export default function Test() {
    const getRawMessages = async () => {
        const docRef = doc(db, "rawMessages", "45ioBdpdNjOYgtTMOPhp");
        const snapshot = await getDoc(docRef);
        const rawMessage = snapshot.data();
        console.log(rawMessage);
    };

    const sendTestResponse = async () => {
        console.log(sample);
        console.log(JSON.stringify(sample));
    };

    return (
        <Container>
            <GridContainer>
                <GridFlexBox>
                    <Title>Test</Title>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox>
                    <Button variant="contained" onClick={getRawMessages}>
                        Get Raw Messages
                    </Button>
                </GridFlexBox>
                <GridFlexBox>
                    <Button variant="contained" onClick={sendTestResponse}>
                        Send Test Response
                    </Button>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
