export default async function sendTelegramMessage(message, toNumber, fromNumber) {
    console.log(message);
    console.log(toNumber);
    console.log(fromNumber);

    const firebaseFunctionUrl = "https://asia-east2-whatsapp-api-eea64.cloudfunctions.net/sendTelegramMessage";

    try {
        const response = await fetch(firebaseFunctionUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                text: message,
                toNumber: toNumber,
                fromNumber: fromNumber
            })
        });

        if (response.ok) {
            console.log("Telegram message sent successfully");
            return true
        } else {
            console.error("Error sending Telegram message:", response.statusText);
            return false
        }
    } catch (error) {
        console.error("Error sending Telegram message:", error);
        return false
    }
}
