import moment from "moment";
import convertDate from "./convertDate";
import formatDate from "./formatDate";

// convert and format Date
export default function cfDate(date, time) {
    if (time) {
        const newDate = moment(convertDate(date)).format("DD-MM-YY LT");
        return newDate;
    } else {
        const newDate = formatDate(convertDate(date));
        return newDate;
    }
}
