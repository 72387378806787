import { doc, getDoc, onSnapshot } from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [authUser, setAuthUser] = useState();

    useEffect(() => {
        // Local storage persistence for user object
        const userLocal = JSON.parse(localStorage.getItem("user"));
        console.log("userLocal: ", userLocal);
        if (userLocal) {
            setUser(userLocal);
        }

        const auth = getAuth();
        let unsubscribe = () => {};
        onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const userRef = doc(db, "users", authUser.email);
                unsubscribe = onSnapshot(userRef, (userSnap) => {
                    if (userSnap.exists()) {
                        const user = { ...userSnap.data(), id: userSnap.id };
                        setUser(user);
                    } else {
                        notification(
                            "Opps",
                            "You do not have an account in the system. Please check with your admin",
                            "warning"
                        );
                        clearLocalUser(setUser);
                        return;
                    }
                });
            } else {
                clearLocalUser(setUser);
            }
        });
        return () => unsubscribe();
    }, [authUser]);

    return (
        <AuthContext.Provider
            value={{
                user,
                authUser,
                setUser,
                setAuthUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function clearLocalUser(setUser) {
    setUser();
    localStorage.removeItem("user");
    localStorage.removeItem("authUser");
    console.log("Not signed in");
}
