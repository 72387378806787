import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";
import convertDate from "../utils-functions/convertDate";

export default async function loadRawMessages() {
    const ref = collection(db, "rawMessages");
    const snap = await getDocs(ref);
    const messages = mapSnapshot(snap);
    console.log(messages);
    const objects = await Promise.all(
        messages.map((rawMessage) => {
            try {
                let data = rawMessage.entry?.[0]?.changes?.[0]?.value?.whatsapp_business_api_data;
                let message = data?.messages?.[0];
                let contact = data?.contacts?.[0];
                let statuses = data?.statuses?.[0];
                let phone_number_id = data?.phone_number_id;
                let wamId = message?.id;

                let object = {
                    message: message?.text?.body || message?.interactive?.button_reply?.title || "",
                    origin_type: statuses?.conversation?.origin?.type || "",
                    conversation_id: statuses?.conversation?.id || "",
                    conversation_expiry_timestamp: statuses?.conversation?.expiration_timestamp || "",
                    status_id: statuses?.id || wamId || "",
                    status: statuses?.status || "",
                    status_timestamp: statuses?.timestamp || "",
                    phone_number_id,
                    recipient_id: statuses?.recipient_id || "",
                    id: rawMessage.id,
                    fromName: contact?.profile?.name || ""
                };

                if (message) {
                    const roomId = `${phone_number_id}-${message["from"]}`;
                    const flattenMessage = flattenMessageFn(message, phone_number_id, contact, object, roomId);
                    object["status_timestamp"] = flattenMessage.date;
                } else {
                    //Messages from Business
                    const roomId = `${phone_number_id}-${object.recipient_id}`;
                    const flattenMessage = {
                        status: object.status,
                        status_timestamp: new Date(convertDate(object.status_timestamp))
                    };
                }

                return Promise.resolve(object);
            } catch (err) {
                console.log(err);
            }
        })
    );
    return [objects, messages];
}

function flattenMessageFn(message, phone_number_id, contact, object, roomId) {
    let textMessage = "";
    switch (message.type) {
        case "text":
            textMessage = message.text?.body;
            break;
        case "interactive":
            textMessage = message.interactive?.button_reply?.title;
            break;
    }

    let flattenMessage = {
        date: convertDate(message.timestamp),
        toNumber: phone_number_id,
        fromNumber: message.from,
        fromName: contact.profile?.name,
        message: textMessage,
        wamId: object.status_id,
        status: object.status,
        phone_number_id: object.phone_number_id,
        roomId,
        type: message.type,
        button_reply_id: message.interactive?.button_reply?.id,
        button_reply_title: message.interactive?.button_reply?.title
    };

    return flattenMessage;
}
