import { useNavigate } from "react-router-dom";
import { ButtonC, GridContainer, GridDivider, GridFlexBox, Title } from "../themes/themes";
import { Container } from "@mui/system";
import { useContext, useEffect } from "react";
import { NotificationContext } from "../context-utils/NotificationContext";

export default function NotFoundPage() {
    const navigate = useNavigate();
    const { openNotification } = useContext(NotificationContext);

    useEffect(() => {
        openNotification("Opps. I think you are lost.", "warning");
    }, []);

    return (
        <Container maxWidth="sm">
            <GridContainer>
                <GridFlexBox>
                    <Title>Page Not Found</Title>
                </GridFlexBox>
                <GridDivider />
                <GridFlexBox fs>
                    <ButtonC color="warning" onClick={() => navigate(-1)}>
                        Back
                    </ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
