import { blue, green, orange, pink, red, teal } from "@mui/material/colors";

export const MENU_ITEMS = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "", role: "Super Admin" },
    { action: "Sign Out", url: "/sign-out", icon: "logout", color: red[500] },
    { action: "Divider", url: "/", icon: "", role: "Super Admin" },
    { action: "Test", url: "/test-raw-messages-page", icon: "biotech", role: "Super Admin", color: green[500] },
    { action: "Upload Image", url: "/upload-image-from-url-page", icon: "biotech", role: "Super Admin", color: green[500] },
    { action: "Facebook Login", url: "/facebook-login-page", icon: "biotech", role: "Super Admin", color: green[500] },
    { action: "Divider", url: "/", icon: "" }
];

export const MENU_ITEMS_SIGNED_OUT = [
    { action: "Home", url: "/", icon: "home" },
    { action: "Divider", url: "/", icon: "" },
    { action: "Sign In", url: "/sign-in", icon: "login" }
];

export const COPYRIGHT_LINK = "https://djc.ai";

export const WEBSITE_NAME = "DJC AI";

export const APP_TITLE = "DJC AI";

export const HOMEPAGE_LINKS = [
    { title: "View and reply your messages here", text: "Messages Platform", link: "/whatsapp-message-page", color: green[600] },
    { title: "Index your documents", text: "GPT Index Page", link: "/gpt-index-page", color: blue[600] }
];
