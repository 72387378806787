import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadRooms(setRooms, setLoading) {
    try {
        setLoading(true);
        const ref = collection(db, "messages");

        // Create a query that orders the results by 'lastUpdated' in descending order
        const sortedQuery = query(ref, orderBy("lastUpdated", "desc"));

        return onSnapshot(sortedQuery, (snapshot) => {
            const rooms = mapSnapshot(snapshot);
            console.log(rooms);
            setRooms(rooms);
            setLoading(false);
        });
    } catch (error) {
        console.log(error);
        setLoading(false);
    }
}
