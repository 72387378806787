import { Icon, IconButton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import loadMessages from "../functions/loadMessages";
import loadRooms from "../functions/loadRooms";
import sendInteractiveMessage from "../functions/sendInteractiveMessage";
import { whatsappMessage } from "../functions/whatsappMessage";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
    ButtonC,
    GridContainer,
    GridDivider,
    GridFlexBox,
    IconC,
    Loading,
    Name,
    TextFieldC,
    Title
} from "../themes/themes";
import cfDate from "../utils-functions/cfDate";
import useWindowSize from "../hooks/useWindowSize";
import sendTelegramMessage from "./TelegramMessagesPage/sendTelegramMessage";

const FROM_NUMBER = "103179706032387";
const type = "text";

export default function WhatsappMessagePage() {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState();
    const [fromNumber, setFromNumber] = useState(FROM_NUMBER);
    const [toNumber, setToNumber] = useState();
    const [loadedRooms, setLoadedRooms] = useState();
    const [token, setToken] = useState();
    const [showPopup, setShowPopup] = useState(true);
    const [roomId, setRoomId] = useState();

    const { user } = useContext(AuthContext);
    const { openNotification } = useContext(NotificationContext);

    const navigate = useNavigate();
    const windowSize = useWindowSize();

    useEffect(() => {
        let unsubcribe = () => {};
        if (user?.longAccessToken) {
            setToken(user.longAccessToken);
        } else {
            navigate("/facebook-login-page");
        }
        unsubcribe = loadRooms(setRooms, setLoading);
        return () => unsubcribe();
    }, []);

    const handleSend = async () => {
        if (roomId.startsWith("telegram")) {
            const response = await sendTelegramMessage(message, toNumber, fromNumber);
            if (response) {
                setMessage("");
            }
        } else {
            if (!token) {
                openNotification("Please login to Facebook to send messages", "info");
                return;
            }
            const response = await whatsappMessage(token, fromNumber, toNumber, message, type, user, openNotification);
            if (response) {
                setMessage("");
            }
        }
    };

    const handleSendIM = () => {
        sendInteractiveMessage(token, fromNumber, toNumber, "button", user, openNotification);
    };

    const handleClickRoom = (room) => {
        console.log(room);
        setRoomId(room.id);
        setShowPopup(false);
        setToNumber(room.toNumber);
        const fromNumber = room.fromNumber;
        setFromNumber(fromNumber);
        setLoadedRooms([room]);
    };

    return (
        <Container>
            <GridContainer alignItems="flex-start">
                <GridFlexBox w="10%" fs>
                    <IconButton onClick={() => setShowPopup((prev) => !prev)} size="large">
                        <MenuOpenIcon />
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox w="90%">
                    <Title>Messages</Title>
                </GridFlexBox>
                <GridDivider />
                <Loading loading={loading} />
                {showPopup && RoomsSelection(rooms, handleClickRoom)}
                <GridFlexBox>
                    {loadedRooms?.map((room) => (
                        <MessageBoxComponent
                            key={room.id}
                            roomId={room.id}
                            fromNumber={fromNumber}
                            message={message}
                            setMessage={setMessage}
                            handleSend={handleSend}
                            setLoading={setLoading}
                            handleSendIM={handleSendIM}
                            currentRoomId={roomId}
                        />
                    ))}
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}

function RoomsSelection(rooms, handleClickRoom) {
    const maxHeight = window.innerHeight - 150 - 50;
    return (
        <div
            style={{
                position: "fixed",
                top: "150px",
                left: "5%",
                zIndex: 1000,
                backgroundColor: "white",
                padding: "1rem",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                width: "90%",
                maxHeight: `${maxHeight}px`, // Add a maximum height
                overflowY: "auto", // Set overflow-y to auto
                overflowX: "hidden" // Hide horizontal scrollbar
            }}
        >
            <GridContainer>
                {rooms?.map((room) => {
                    const platform = room.id.startsWith("telegram") ? " (Telegram)" : " (Whatsapp)";
                    const roomName = (room.clientName ? room.clientName : room.id) + platform;
                    return (
                        <React.Fragment key={room.id}>
                            <GridFlexBox fs>
                                <IconButton onClick={() => handleClickRoom(room)} style={{ padding: 0 }}>
                                    <IconC cp>face</IconC>
                                    <Name> {roomName}</Name>
                                </IconButton>
                            </GridFlexBox>
                            <GridDivider />
                        </React.Fragment>
                    );
                })}
            </GridContainer>
        </div>
    );
}

function MessageBoxComponent({
    roomId,
    fromNumber,
    message,
    setMessage,
    handleSend,
    setLoading,
    handleSendIM,
    currentRoomId
}) {
    const [messages, setMessages] = useState();

    useEffect(() => {
        let unsubcribe = () => {};
        unsubcribe = loadMessages(roomId, setMessages, setLoading);
        return () => unsubcribe();
    }, []);

    // let display = "none";
    let display = "flex";
    if (currentRoomId === roomId) display = "flex";

    return (
        <GridContainer display={display} pr1>
            {messages?.map((m) => {
                // console.log(m);
                return (
                    <React.Fragment key={m.id}>
                        <GridFlexBox gap={1} w="85%" justifyContent={checkSender(m.fromNumber, fromNumber)}>
                            <Name fs10 onClick={() => console.log(m)}>
                                {cfDate(m.date, true)}
                            </Name>
                            <Name>{m.fromName || ""}</Name>
                        </GridFlexBox>{" "}
                        <GridFlexBox w="15%">
                            <Icon style={{ color: checkStatus(m.status).color }}>{checkStatus(m.status).icon}</Icon>
                        </GridFlexBox>
                        <GridFlexBox w="100%" justifyContent={checkSender(m.fromNumber, fromNumber)}>
                            <Typography variant="body1" sx={{ mt: 1, mb: 2, whiteSpace: "pre-wrap" }}>
                                {m.message}
                            </Typography>
                            <Name></Name>
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                );
            })}
            <TextFieldC value={message} onChange={(e) => setMessage(e.target.value)} />
            <GridFlexBox>
                <ButtonC onClick={handleSend}>Send</ButtonC>
            </GridFlexBox>
            <GridFlexBox>
                <ButtonC onClick={handleSendIM}>Send Interactive Message</ButtonC>
            </GridFlexBox>
        </GridContainer>
    );
}

function checkSender(number, fromNumber) {
    if (number === fromNumber) {
        return "flex-end";
    } else {
        return "flex-start";
    }
}

const checkStatus = (status) => {
    let icon = "";
    let color = "black";
    switch (status) {
        case "sent":
            icon = "";
            color = "black";
            break;
        case "delivered":
            icon = "done";
            color = "black";
            break;
        case "read":
            icon = "done_all";
            color = "teal";
            break;
        case "failed":
            icon = "sms_failed";
            color = "red";
            break;
        default:
            icon = "";
            color = "black";
    }
    return { icon, color };
};
