import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { COPYRIGHT_LINK, WEBSITE_NAME } from "../settings/settings";
import { notification } from "../utils-functions/notification";
import signUp from "../functions/signUp";
import { AuthContext } from "../context/AuthContext";
import { useState } from "react";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextFieldC } from "../themes/themes";
import { NotificationContext } from "../context-utils/NotificationContext";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href={COPYRIGHT_LINK}>
                {WEBSITE_NAME}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function SignUpPage() {
    const { setUser, setAuthUser } = React.useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const { openNotification } = React.useContext(NotificationContext);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const firstName = data.get("firstName");
        const lastName = data.get("lastName");
        const email = data.get("email");
        const phone = data.get("phone");
        const password = data.get("password");
        const password2 = data.get("password2");
        const secretCode = data.get("secretCode");

        if (password !== password2) {
            notification("Passwords not match", "Please make sure both passwords are the same", "warning");
        } else if (secretCode !== "nnc6226") {
            notification("Secret code is not correct", "Please get the secret code from your referral", "warning");
        } else {
            setLoading(true);
            const user = await signUp(firstName, lastName, email.toLowerCase(), phone, password, setUser, setAuthUser);
            if (user) {
                console.log(user)
                openNotification("Signed in successfully", "success");
            }
            setLoading(false);
            if (user) navigate("/");
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Box sx={{ mt: 3 }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container>
                            <TextFieldC name="firstName" label="First Name" autoFocus m1 />
                            <TextFieldC name="lastName" label="Last Name" />
                            <TextFieldC name="email" label="Email Address" email />
                            <TextFieldC name="phone" label="Mobile Phone" />
                            <TextFieldC name="password" label="Password" password />
                            <TextFieldC name="password2" label="Reenter Password" password />
                            <TextFieldC name="secretCode" label="Secret Code" />
                        </Grid>
                        {loading && <LinearProgress />}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign Up
                        </Button>
                    </form>

                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/sign-in" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    );
}
