import React, { useState } from "react";
import { ButtonC, GridContainer, GridFlexBox, GridItem, TextFieldC } from "../themes/themes";
import { useRef } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import uploadToStorage from "../functions/UploadToStorage/uploadToStorage";

const UploadFiles = () => {
    const [files, setFiles] = useState([]);

    const inputRef = useRef(null);

    const handleFileUpload = (event) => {
        const uploadedFiles = Array.from(event.target.files).map((file) => ({
            file,
            description: ""
        }));
        setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    };

    const handleDescriptionChange = (index, description) => {
        setFiles((prevFiles) => prevFiles.map((file, i) => (i === index ? { ...file, description } : file)));
    };

    const handleDelete = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleSubmit = async () => {
        const allDescriptionsFilled = files.every(({ description }) => description.trim() !== "");

        if (!allDescriptionsFilled) {
            alert("Please fill in all descriptions.");
            return;
        }

        const response = await uploadToStorage(files);
        if (response) {
            alert("Upload successful!");
            setFiles([]);
        } else {
            alert("Upload failed.");
        }
    };

    return (
        <GridContainer>
            <GridItem>
                <input
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    onClick={() => (inputRef.current.value = null)}
                    style={{ display: "none" }}
                    id="upload-files"
                    ref={inputRef}
                />
                <label htmlFor="upload-files">
                    <ButtonC component="span" onClick={handleClick}>
                        Upload Files
                    </ButtonC>
                </label>
            </GridItem>
            {files.map(({ file, description }, index) => (
                <GridContainer key={index}>
                    <GridFlexBox xs={6}>
                        {file.name}
                        <IconButton onClick={() => handleDelete(index)} style={{ paddingLeft: "4px" }}>
                            <DeleteIcon />
                        </IconButton>
                    </GridFlexBox>
                    <GridFlexBox xs={6}>
                        <TextFieldC
                            label="Description"
                            value={description}
                            onChange={(event) => handleDescriptionChange(index, event.target.value)}
                            fullWidth={true}
                        />
                    </GridFlexBox>
                </GridContainer>
            ))}
            <GridItem>
                <ButtonC onClick={handleSubmit}>Submit</ButtonC>
            </GridItem>
        </GridContainer>
    );
};

export default UploadFiles;
