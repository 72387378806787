export const sample = {
    entry: [
        {
            changes: [
                {
                    field: "messages",
                    value: {
                        whatsapp_business_api_data: {
                            phone_number_id: "103179706032387",
                            messages: [
                                {
                                    text: {
                                        body: "Hello 123"
                                    },
                                    timestamp: "1676017553",
                                    from: "60126098229",
                                    type: "text",
                                    id: "wamid.HBgLNjAxMjYwOTgyMjkVAgASGCAxNjFDRDE4MzNBRTEzM0I5OTkzNzA3NEEwMjYzRjYxMwA="
                                }
                            ],
                            display_phone_number: "15550532054",
                            contacts: [
                                {
                                    wa_id: "60126098229",
                                    profile: {
                                        name: "Dave Chong"
                                    }
                                }
                            ]
                        }
                    }
                }
            ],
            id: "109251002084497"
        }
    ],
    object: "whatsapp_business_account"
};

const msg = {
    severity: "INFO",
    message:
        "chatHistory:  [\n  {\n    id: 'wamid.HBgLNjAxMjYwOTgyMjkVAgASGCAxNjFDRDE4MzNBRTEzM0I5OTkzNzA3NEEwMjYzRjYxMwA=',\n    date: Timestamp { _seconds: 1676017553, _nanoseconds: 0 },\n    fromNumber: '60126098229',\n    phone_number_id: '103179706032387',\n    wamId: 'wamid.HBgLNjAxMjYwOTgyMjkVAgASGCAxNjFDRDE4MzNBRTEzM0I5OTkzNzA3NEEwMjYzRjYxMwA=',\n    fromName: 'Dave Chong',\n    message: 'Hello 123',\n    type: 'text',\n    toNumber: '103179706032387',\n    roomId: '103179706032387-60126098229',\n    status: ''\n  }\n]"
};
