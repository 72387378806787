import { Container } from "@mui/material";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";

export default function TelegramMessagesPage() {
    return (
        <Container maxWidth={false}>
            <GridContainer>
                <GridFlexBox>
                    <Title>Telegram Messages</Title>
                </GridFlexBox>
                <GridDivider />
                
            </GridContainer>
        </Container>
    );
}


