import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonC, GridDivider, GridFlexBox } from "../themes/themes";
import { Typography } from "@mui/material";
import { HOMEPAGE_LINKS } from "../settings/settings";
import { useState } from "react";

export default function HomepageLinks() {
    const navigate = useNavigate();

    const [links] = useState(HOMEPAGE_LINKS);

    return (
        <React.Fragment>
            {links.map((item) => (
                <React.Fragment key={item.link}>
                    <GridFlexBox>
                        <Typography variant="body2">{item.title}</Typography>
                    </GridFlexBox>
                    <GridFlexBox>
                        <ButtonC onClick={() => navigate(item.link)} bgColor={item.color}>
                            {item.text}
                        </ButtonC>
                    </GridFlexBox>
                    <GridDivider />
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
