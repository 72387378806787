import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { AuthProvider } from "./context/AuthContext";
import { ReactNotifications } from "react-notifications-component";
import { ListProvider } from "./context-utils/ListContext";
import { UsersProvider } from "./context/UsersContext";
import { ConfirmationProvider } from "./context-utils/ConfirmationContext";
import { InputProvider } from "./context-utils/InputContext";
import { StateProvider } from "./context/StateContext";
import { customThemes } from "./themes/customThemes";
import { NotificationProvider } from "./context-utils/NotificationContext";

const theme = createTheme(customThemes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <ReactNotifications />
        <BrowserRouter>
            <NotificationProvider>
                <ConfirmationProvider>
                    <InputProvider>
                        <ListProvider>
                            <StateProvider>
                                <UsersProvider>
                                    <AuthProvider>
                                        <CssBaseline />
                                        <ThemeProvider theme={theme}>
                                            <App />
                                        </ThemeProvider>
                                    </AuthProvider>
                                </UsersProvider>
                            </StateProvider>
                        </ListProvider>
                    </InputProvider>
                </ConfirmationProvider>
            </NotificationProvider>
        </BrowserRouter>
    </React.Fragment>
);
