import { ButtonC, GridContainer, GridFlexBox, Name } from "../themes/themes";
import React, { useEffect, useState } from "react";
import loadRawMessages from "../functions/loadRawMessages";
import cfDate from "../utils-functions/cfDate";
import { Box, IconButton } from "@mui/material";
import convertDate from "../utils-functions/convertDate";

export default function TestRawMessagesPage() {
    const [objects, setObjects] = useState();
    const [messages, setMessages] = useState();
    const [idObjects, setIdObjects] = useState();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const [objects, messages] = await loadRawMessages();
        console.log(objects);
        setObjects(objects);
        setMessages(messages);
        const idObjects = convertObjects(objects);
        setIdObjects(idObjects);
    };

    const handleClick = (id) => {
        console.log(id);
        const index = objects.findIndex((object) => object.id === id);
        console.log(messages[index]?.entry?.[0]?.changes?.[0]?.value.whatsapp_business_api_data);
        console.log(objects[index]);
        console.log(retrieveData(messages[index]));
    };

    return (
        <Box m={1}>
            <GridContainer>
                {idObjects &&
                    Object.keys(idObjects)?.map((status_id) => (
                        <React.Fragment key={status_id}>
                            <GridFlexBox fs>
                                <Name bold fs10>
                                    {status_id}
                                </Name>
                            </GridFlexBox>
                            {displayObject(idObjects[status_id], handleClick)}
                        </React.Fragment>
                    ))}

                <GridFlexBox>
                    <ButtonC small onClick={() => loadData()}>
                        Refresh
                    </ButtonC>
                </GridFlexBox>
            </GridContainer>
        </Box>
    );
}

const convertObjects = (objects) => {
    if (!objects) return;
    let idObjects = {};
    const newObjects = [...objects];
    newObjects?.sort((a, b) => convertDate(a.status_timestamp) - convertDate(b.status_timestamp));
    newObjects?.forEach((obj) => {
        if (idObjects[obj.status_id]) {
            idObjects[obj.status_id].push({ ...obj });
        } else {
            idObjects[obj.status_id] = [{ ...obj }];
        }
    });
    return idObjects;
};

function displayObject(displayObjects, handleClick) {
    return (
        <React.Fragment>
            {displayObjects?.map((object, i) => {
                const {
                    message,
                    origin_type,
                    conversation_id,
                    conversation_expiry_timestamp,
                    status_id,
                    status,
                    status_timestamp,
                    recipient_id,
                    id,
                    fromName,
                    phone_number_id
                } = object;
                return (
                    <React.Fragment key={id}>
                        <GridFlexBox w="2%">
                            <IconButton onClick={() => handleClick(id)}>
                                <Name fs10>{i + 1}</Name>
                            </IconButton>
                        </GridFlexBox>
                        <GridFlexBox w="5%">
                            <Name fs10>{id}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="15%">
                            <Name fs10>{status_id}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="8%">
                            <Name fs10>{status}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%">
                            <Name fs10>{origin_type}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%">
                            <Name fs10>{conversation_id}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%">
                            <Name fs10>{conversation_expiry_timestamp}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%" fs>
                            <Name fs10>{cfDate(status_timestamp, true)}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="7%">
                            <Name fs10>{phone_number_id}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="6%">
                            <Name fs10>{recipient_id}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="7%">
                            <Name fs10>{fromName}</Name>
                        </GridFlexBox>
                        <GridFlexBox w="10%">
                            <Name fs10>{message}</Name>
                        </GridFlexBox>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
}

const retrieveData = (rawMessage) => {
    let data = rawMessage.entry?.[0]?.changes?.[0]?.value?.whatsapp_business_api_data;
    let message = data?.messages?.[0];
    let contact = data?.contacts?.[0];
    let statuses = data?.statuses?.[0];
    let phone_number_id = rawMessage.entry?.[0]?.changes?.[0]?.value?.whatsapp_business_api_data?.phone_number_id;
    let object = {
        message: message?.text?.body || "",
        origin_type: statuses?.conversation?.origin?.type || "",
        conversation_id: statuses?.conversation?.id || "",
        conversation_expiry_timestamp: statuses?.conversation?.expiration_timestamp || "",
        status_id: statuses?.id || "",
        status: statuses?.status || "",
        status_timestamp: statuses?.timestamp || "",
        phone_number_id,
        recipient_id: statuses?.recipient_id || "",
        id: rawMessage.id,
        fromName: contact?.profile?.name || ""
    };
    return object;
};
