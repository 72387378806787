import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { GridContainer } from "../themes/themes";
import { GridDivider, GridFlexBox } from "../themes/themes";
import HomepageLinks from "../components/HomepageLinks";
import { NotificationContext } from "../context-utils/NotificationContext";
import callTestFunction from "../tests/callTestFunction";

export default function HomePage() {
    const { user } = useContext(AuthContext);
    const { openNotification } = useContext(NotificationContext);

    const handleClick = () => {
        callTestFunction();
        openNotification("Error", "info");
    };

    return (
        <Box m={1}>
            <GridContainer>
                <GridFlexBox>
                    <Typography variant="h5" color="inherit">
                        {user ? `Welcome ${user.displayName}` : "Homepage"}
                    </Typography>
                </GridFlexBox>
                <GridDivider />
                <HomepageLinks />
            </GridContainer>
        </Box>
    );
}
