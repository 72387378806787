import {
    Avatar,
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField
} from "@mui/material";
import { useState } from "react";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";

export default function ListComponent({
    onClose,
    selectedValue,
    open,
    objects,
    displayName,
    title,
    icon,
    addAccount,
    search
}) {
    const [searchValue, setSearchValue] = useState("");

    const handleClose = () => {
        onClose(selectedValue);
        setSearchValue("");
    };

    const handleListItemClick = (value) => {
        onClose(value);
        setSearchValue("");
    };

    let filteredObjects = objects.filter((object) => {
        if (object[displayName]) {
            if (object[displayName].toString().toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        }
    });

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle id="simple-dialog-title" style={{ padding: "10px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <IconButton size="small" onClick={handleClose}>
                                <Icon>clear</Icon>
                            </IconButton>
                            {title}
                        </Box>
                    </Grid>
                    {search && (
                        <Grid item xs={12}>
                            <Box m={1}>
                                <TextField
                                    variant="outlined"
                                    label="search"
                                    fullWidth
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>
            <div
                style={{
                    overflowX: "hidden",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <List style={{ minWidth: "350px", height: "600px" }}>
                    {!arrayIsEmpty(objects) &&
                        filteredObjects.map((object, i) => (
                            <ListItem button onClick={() => handleListItemClick(object)} key={i}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Icon>{icon}</Icon>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={object[displayName]} />
                            </ListItem>
                        ))}
                    {addAccount && (
                        <ListItem onClick={() => handleListItemClick("addAccount")}>
                            <ListItemAvatar>
                                <Avatar>
                                    <Icon>add</Icon>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Add account" />
                        </ListItem>
                    )}
                </List>
            </div>
        </Dialog>
    );
}
