import axios from "axios";
const appId = process.env.REACT_APP_FBAPPID;
const appSecret = process.env.REACT_APP_APPSECRET;

export async function getLongLivedUserToken(shortToken) {
    const url =
        `https://graph.facebook.com/v16.0/oauth/access_token?` +
        `grant_type=fb_exchange_token&` +
        `client_id=${appId}&` +
        `client_secret=${appSecret}&` +
        `fb_exchange_token=${shortToken}`;

    const response = await axios(url);
    return response.data.access_token;
}
